<template>
  <div   justify="center" >
    <v-dialog
      v-model="dialog"
      persistent
      :fullscreen="true"
      hide-overlay
      
    >
      <v-card  :style="{'background' : $vuetify.theme.themes[theme].background}">
        <v-app-bar
        dense
        fixed
          dark
          color="secondary"
        >
          <v-btn
            icon
            dark
            @click="closeDialog"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>Розсилки</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>
        
        
       <keep-alive>
        <TableDetailsMessages style="padding-top: 54px;" :urlId="urlId" />
       </keep-alive>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

    
  import TableDetailsMessages from '@/components/Messages/TableDetailsMessages.vue'
	export default {
    components:{
        TableDetailsMessages
    },
    name: 'messages-detail-modal',
		data: () => ({
        dialog: false,
        urlId: null,
        is_component: ''
    }),
    computed: {
        theme(){
          return this.$vuetify.theme.dark && 'dark' || 'light'
        }
    },
    
    watch:{
        dialog(){
            if (this.dialog){
                //this.$route.params.id = this.paramsUrlId
                //this.is_component = 'TableDetailsMessage'
            }else{
              //this.$emit('update-data')
              this.is_component = ''
              this.urlId = null
            }
            //console.log(this.$route.params.id)
        }
        
    },
    methods: {
        
        closeDialog(){
            
            this.dialog = false
            this.urlId =  null,
            this.is_component = ''
        },
        
    
    }
    }
</script>