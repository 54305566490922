<template>
    <v-container class="messages" :style="{'max-width': '1300px'}">
      
    <TableMessages :user="user"/>
    
    </v-container>
</template>

<script>
//import axios from "axios"
import {mapGetters} from 'vuex'
import TableMessages from '@/components/Messages/TableMessages.vue'

export default {
    name: 'Messages',
    components:{
      TableMessages
    },

    data: () => ({
      loading: false,
    }),
    mounted(){
      //console.log("mounted page Notification")
    },
    computed: {
      ...mapGetters({
        user: "auth/user",
      }),
    },
    methods:{

    }

}
    

</script>
